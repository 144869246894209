<template>
  <div class="forget-password">
    <img src="../assets/images/logo.png" alt="logo" class="logo" />
    <h1>忘记密码</h1>
    <Form :model="form" :rules="ruleValidate" ref="form">
      <FormItem prop="email">
        <Input type="text" v-model="form.email" placeholder="邮箱地址">
          <Icon type="ios-mail" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem>
        <div class="button-container">
          <Button
            type="primary"
            size="large"
            @click="resetPassword"
            :loading="isLoading"
          >
            重置密码
          </Button>
        </div>
      </FormItem>
    </Form>
    <RouterLink to="/login" class="action-link">登录</RouterLink>
  </div>
</template>

<script>
import { Form, FormItem, Input, Icon, Button, Message } from "view-design";
import { forgetPassword } from "../service/auth";
export default {
  name: "ForgetPassword",
  components: {
    Form,
    FormItem,
    Input,
    Icon,
    Button,
  },
  data() {
    return {
      form: {
        email: "",
      },
      ruleValidate: {
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          { type: "email", message: "邮箱格式错误", trigger: "blur" },
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    async resetPassword() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;
            await forgetPassword({
              email: this.form.email,
            });
            Message.success("重置密码邮件已发送");
            this.form.email = "";
          } catch (error) {
            Message.error(error.response.data.message);
            console.error(error);
          }
          this.isLoading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.forget-password {
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  width: 80%;
  display: block;
  margin: 100px auto 50px;
}

.button-container {
  text-align: center;
}

.forget-password h1 {
  text-align: center;
  margin-bottom: 20px;
}

.action-link {
  text-align: center;
  display: block;
}
</style>
